// Imports => Constants
import { COLUMNS } from '@constants';
import { getProp, mapItem } from '../../../utilities/ac-fp-utils';

export const getUsersTableOptions = (data) => {
  const { users, headers } = mapItem({
    users: getProp('users.data', []),
    headers: getProp('users.headers', []),
  })(data);
  return {
    list: users,
    options: {
      rows: users,
      columns: headers,
      pagination: null,
    },
  };
};

export const getContractsTableOptions = (data) => {
  const { headers, contracts } = mapItem({
    contracts: getProp('contracts.data', []),
    headers: getProp('contracts.headers', []),
  })(data);
  return {
    list: contracts,
    options: {
      rows: contracts,
      columns: headers,
      pagination: null,
    },
  };
};

export const getProjectsTableOptions = (data) => {
  const { headers, projects } = mapItem({
    projects: getProp('projects.data', []),
    headers: getProp('projects.headers', []),
  })(data);
  return {
    list: projects,
    options: {
      rows: projects,
      columns: headers,
      pagination: null,
    },
  };
};
