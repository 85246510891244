// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';

const _CLASSES = {
	MAIN: 'ac-control-unit-detail-location-tab',
};

const AcControlUnitDetailLocationTab = ({ data }) => {
	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	return (
		<div className={getMainClassNames}>
			<AcContainer fluid>
				<AcRow>
					<AcColumn>[map]</AcColumn>
				</AcRow>
			</AcContainer>
		</div>
	);
};

export default memo(AcControlUnitDetailLocationTab);
