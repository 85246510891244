// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, THEMES, TITLES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcEditHammerTypeModal from '@molecules/ac-edit-hammer-type-modal/ac-edit-hammer-type-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
import AcHeroVisual from '@components/ac-hero-visual/ac-hero-visual.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcDropzone from '@atoms/ac-dropzone/ac-dropzone.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-hammer-type-detail-overview-tab',
};

const AcHammerTypeDetailOverviewTab = ({
  data,
  store: { hammer_types, ui },
}) => {
  const { can } = usePermissions();
  const { is_loading, is_busy } = hammer_types;

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_HAMMER_TYPE}: ${data.name}`,
      body: <AcEditHammerTypeModal data={data} submit={hammer_types.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleRemoveMedia = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    hammer_types.delete_media(data.id);
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;
    const {
      name,
      type,
      series,
      description,
      jean_lutz_hammer_type,
      article_number,
    } = data;

    const items = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Type',
        value: type || '-',
      },
      {
        label: 'Series',
        value: series || '-',
      },
      {
        label: 'Article number',
        value: article_number || '-',
      },
      {
        label: 'Description',
        value: description || '-',
      },
      {
        label: 'Sensor configuration',
        value: jean_lutz_hammer_type || '-',
      },
    ];

    const edit = can(PERMISSIONS.EQUIPMENT_TYPE.UPDATE) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={items} edit={edit} />;
  }, [data]);

  const renderDropzone = useMemo(() => {
    if (!AcIsSet(data) || !AcIsSet(data.images)) return null;

    return (
      <AcDropzone
        id={data.id}
        value={data.images.header}
        callback={hammer_types.update}
        loading={is_busy}
      />
    );
  }, [data, is_busy]);

  const getRemoveMediaButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      disabled: is_loading || is_busy,
      title: 'Remove',
      callback: handleRemoveMedia,
    };
  }, [is_loading, is_busy]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            {renderDetails}
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcCard flat>
              <AcHeading tag={'h2'} rank={6}>
                Equipment image
              </AcHeading>
              {renderDropzone}
              {AcIsSet(data) && AcIsSet(data.images.header) && (
                <div className={'h-margin-top-15 h-flex-h-align-end'}>
                  <AcButton {...getRemoveMediaButtonOptions}>
                    <span>Remove</span>
                  </AcButton>
                </div>
              )}{' '}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcHammerTypeDetailOverviewTab));
