import React, { useEffect, useMemo } from 'react';
import loadable from '@loadable/component';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const AcOrginalOrderList = ({ contract, store: { contracts } }) => {
  const { orderList, is_busy } = contracts;

  useEffect(() => {
    contracts.getOrderList(contract?.id);
  }, [contract?.id]);

  const renderOrderList = useMemo(() => {
    if (is_busy) {
      return (
        <div className={'ac-wizard-loading'}>
          <AcLoader loading={true} />
        </div>
      );
    }

    if (!orderList?.length) {
      return <AcEmptyBlock message={'No results found to display.'} />;
    }

    const columns = [
      {
        key: 'description',
        label: 'Description',
        visible: true,
        size: 1,
        style: {
          width: '100%!important',
          maxWidth: 'none',
        },
      },
      {
        key: 'part_code',
        label: 'Part code',
        visible: true,
      },
      {
        key: 'quantity',
        label: 'Quantity',
        visible: true,
      },
      {
        key: 'serial_number',
        label: 'Serial no.',
        visible: true,
      },
    ];

    const options = {
      columns,
      rows: orderList,
      pagination: null,
    };
    return <AcTable {...options} />;
  }, [is_busy, orderList]);

  return (
    <div
      style={{
        backgroundColor: '#fff',
        margin: 0,
        fontSize: '12px!important',
        width: '100%',
        height: '100%',
        padding: '12px',
      }}
    >
      <AcContainer fluid>
        <AcRow className={'h-margin-y-0'}>
          <AcColumn className={'h-margin-left-10 h-margin-right-10'}>
            <AcHeading tag={'h1'} rank={5}>
              Contract {contract?.name} - Original order list
            </AcHeading>
          </AcColumn>
        </AcRow>
        <AcRow>
          <AcColumn className={'h-margin-left-10 h-margin-right-10'}>
            {renderOrderList}
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcOrginalOrderList));
