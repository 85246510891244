// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Config
import config from '@config';

// Imports => Constants
import { DATETIME_FORMATS, ENDPOINTS, KEYS, TITLES, TYPES } from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcTag from '@atoms/ac-tag/ac-tag.web';
import AcProgressCircularWeb from '@atoms/ac-progress-circular/ac-progress-circular.web';

const _CLASSES = {
	MAIN: 'ac-control-unit-detail-liveview-tab',
	PROGRESS: 'ac-control-unit-liveview-progress',
	IMAGE: 'ac-image-wrp ac-control-unit-liveview-image',
};

const AcControlUnitDetailLiveviewTab = ({ store, data }) => {
	const [timer, setTimer] = useState(5000);
	const [updated, setUpdated] = useState(null);
	const [source, setSource] = useState(data && data.live_view);

	const handleUpdateLiveView = () => {
		if (AcIsSet(store) && AcIsSet(store.get_liveview)) {
			store.get_liveview(data.id).then((response) => {
				if (response) {
					setSource(response);
					setUpdated(Date.now());
				}
			});
		}
	};

	useEffect(() => {
		handleUpdateLiveView();
	}, []);

	const handleSetTimer = (opt) => {
		if (!AcIsSet(opt)) return;

		setTimer(opt);
		setUpdated(null);
		handleUpdateLiveView();
	};

	const renderActions = useMemo(() => {
		const actions = [
			{
				id: 5 * 1000,
				label: `5 seconds`,
				active: timer === 5 * 1000,
				callback: handleSetTimer,
				className: 'h-margin-bottom-0',
			},
			{
				id: 15 * 1000,
				label: `15 seconds`,
				active: timer === 15 * 1000,
				callback: handleSetTimer,
				className: 'h-margin-bottom-0',
			},
			{
				id: 30 * 1000,
				label: `30 seconds`,
				active: timer === 30 * 1000,
				callback: handleSetTimer,
				className: 'h-margin-bottom-0',
			},
			{
				id: 60 * 1000,
				label: `1 minute`,
				active: timer === 60 * 1000,
				callback: handleSetTimer,
				className: 'h-margin-bottom-0',
			},
		];

		const len = actions.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const item = actions[n];

			const object = <AcTag key={`ac-action-${item.id}`} {...item} />;

			result.push(object);
		}

		return (
			<AcContainer>
				<AcRow noGutters>
					<AcCard
						dense
						flat
						className={
							'h-margin-y-15 h-padding-x-35 h-padding-y-20 h-text--align-center'
						}
					>
						<AcRow className={'h-margin-top-0 h-margin-bottom-0'}>
							<AcColumn xs={12} className={'h-padding-x-0'}>
								<AcHeading rank={6} className={'h-margin-bottom-10'}>
									Refresh interval settings
								</AcHeading>
							</AcColumn>
						</AcRow>
						<AcRow className={'h-flex-h-align-center'}>{result}</AcRow>
					</AcCard>
				</AcRow>
			</AcContainer>
		);
	}, [updated, timer]);

	const renderTitle = useMemo(() => {
		const title = (data && data.object_no) || '';
		const type =
			(data && data.equipment_type && data.equipment_type.name) || null;

		return (
			<AcHeading
				tag={'h1'}
				rank={3}
				className={'h-margin-top-10 h-margin-bottom-25'}
			>
				{title}
				{type && <sub>{type}</sub>}
			</AcHeading>
		);
	}, [data]);

	const getImageClassNames = useMemo(() => {
		return clsx(_CLASSES.IMAGE);
	});

	const renderImage = useMemo(() => {
		if (!AcIsSet(source)) return null;

		return (
			<AcRow noGutters>
				<AcCard
					dense
					flat
					className={
						'h-margin-bottom-15 h-padding-y-15 h-padding-x-15 h-text--align-center'
					}
				>
					<AcContainer fluid>
						<AcRow noGutters>
							<AcColumn xs={12}>{renderTitle}</AcColumn>
						</AcRow>
						<AcRow noGutters>
							<AcColumn xs={12}>
								<img src={source} className={getImageClassNames} />
							</AcColumn>
						</AcRow>
					</AcContainer>
				</AcCard>
			</AcRow>
		);
	}, [data, source, renderTitle]);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	return (
		<div className={getMainClassNames}>
			{renderActions}
			<AcContainer>
				<AcProgressCircularWeb
					className={_CLASSES.PROGRESS}
					timerStart={updated}
					progress={!updated && timer / 1000}
					timer={!!updated && timer}
					onComplete={handleUpdateLiveView}
				/>
				{renderImage}
			</AcContainer>
		</div>
	);
};

export default memo(AcControlUnitDetailLiveviewTab);
