import AcControlUnitDetailLiveviewTab from './liveview/liveview.web';
import AcControlUnitDetailLocationTab from './location/location.web';
import AcControlUnitDetailOverviewTab from './overview/overview.web';
import AcControlUnitDetailReportsTab from './reports/reports.web';
import AcControlUnitDetailSettingsTab from './settings/settings.web';

export default {
	Liveview: AcControlUnitDetailLiveviewTab,
	Location: AcControlUnitDetailLocationTab,
	Overview: AcControlUnitDetailOverviewTab,
	Reports: AcControlUnitDetailReportsTab,
	Settings: AcControlUnitDetailSettingsTab,
};
